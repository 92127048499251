@font-face {
    font-family: 'Gordita';
    ascent-override: 80%;
    descent-override: 0%;
    src: url('./fonts/Gordita Medium.otf') format('opentype');
    font-weight: 500;
    font-style: normal;
  }

  @font-face {
    font-family: 'Gordita';
    ascent-override: 80%;
    descent-override: 0%;
    src: url('./fonts/Gordita Light.otf') format('opentype');
    font-weight: 300;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Gordita';
    ascent-override: 80%;
    descent-override: 0%;
    src: url('./fonts/Gordita Bold.otf') format('opentype');
    font-weight: bold;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Gordita';
    ascent-override: 80%;
    descent-override: 0%;
    src: url('./fonts/Gordita Medium Italic.otf') format('opentype');
    font-weight: 500;
    font-style: italic;
  }
  
  @font-face {
    font-family: 'Gordita';
    ascent-override: 80%;
    descent-override: 0%;
    src: url('./fonts/Gordita Bold Italic.otf') format('opentype');
    font-weight: bold;
    font-style: italic;
  }
  
  @font-face {
    font-family: 'Gordita';
    ascent-override: 80%;
    descent-override: 0%;
    src: url('./fonts/Gordita Regular Italic.otf') format('opentype');
    font-weight: normal;
    font-style: italic;
  }
  
   @font-face {
    font-family: 'Gordita';
    ascent-override: 80%;
    descent-override: 0%;
    src: url('./fonts/Gordita Regular.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
  } 

  @font-face {
    font-family: 'Gordita';
    ascent-override: 80%;
    descent-override: 0%;
    src: url('./fonts/Gordita Black Italic.otf') format('opentype');
    font-weight: 900;
    font-style: italic;
  }
  
  @font-face {
    font-family: 'Gordita';
    ascent-override: 80%;
    descent-override: 0%;
    src: url('./fonts/Gordita Black.otf') format('opentype');
    font-weight: 900;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Gordita';
    ascent-override: 80%;
    descent-override: 0%;
    src: url('./fonts/Gordita Light Italic.otf') format('opentype');
    font-weight: 300;
    font-style: italic;
  } 
